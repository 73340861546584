<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form
          ref="conditionsFormRef"
          :model="conditions"
          inline
          size="small"
        >
          <el-form-item label="车队">
            <el-select
              v-model="conditions.fleetId"
              filterable
              :clearable="!isFleet"
            >
              <el-option
                :key="item.id"
                v-for="item in fleetOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="磅单编号" prop="poundCode">
            <el-input v-model="conditions.poundCode" clearable> </el-input>
          </el-form-item>
          <el-form-item label="运单编号" prop="transportCode">
            <el-input v-model="conditions.transportCode" clearable> </el-input>
          </el-form-item>
          <el-form-item label="发货公司" prop="sendOwnerId">
            <el-select v-model="conditions.sendOwnerId" clearable filterable>
              <el-option
                :key="item.id"
                v-for="item in ownerOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收货公司" prop="receiveOwnerId">
            <el-select v-model="conditions.receiveOwnerId" clearable filterable>
              <el-option
                :key="item.id"
                v-for="item in ownerOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-collapse-transition>
            <div v-show="showMoreConditions">
              <el-form-item label="车牌号" prop="plate">
                <el-input v-model="conditions.plate" clearable> </el-input>
              </el-form-item>
              <el-form-item label="司机姓名" prop="driverName">
                <el-input v-model="conditions.driverName" clearable> </el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="driverPhone">
                <el-input v-model="conditions.driverPhone" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="装货日期" prop="loadingDate">
                <el-date-picker
                  v-model="conditions.loadingDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="过磅时间" prop="mwitemdate">
                <el-date-picker
                  v-model="conditions.mwitemdate"
                  type="date"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="毛重" prop="roughWeight">
                <el-input v-model="conditions.roughWeight" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="皮重" prop="tare">
                <el-input v-model="conditions.tare" clearable> </el-input>
              </el-form-item>
              <el-form-item label="净重" prop="suttle">
                <el-input v-model="conditions.suttle" clearable> </el-input>
              </el-form-item></div
          ></el-collapse-transition>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
            <el-button
              type="info"
              plain
              class="buttons"
              @click="handleReset('conditionsFormRef')"
              >重置</el-button
            >
            <el-button
              type="primary"
              plain
              class="buttons"
              @click="handleCollapse"
              >{{ showMoreConditions ? "收起" : "更多" }}</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-dropdown
              trigger="click"
              style="margin-right: 10px"
              @command="handleCommand"
            >
              <el-button type="primary">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">全部导出</el-dropdown-item>
                <el-dropdown-item command="b">批量导出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table
          border
          :data="tableList"
          style="width: 100%"
          ref="tableRef"
          row-key="id"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            reserve-selection
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            min-width="180"
            show-overflow-tooltip
            label="运单编号"
            prop="transportCode"
          ></el-table-column>
          <el-table-column
            min-width="180"
            show-overflow-tooltip
            label="磅单编号"
            prop="poundCode"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="车牌号"
            prop="plate"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="司机姓名"
            prop="driverName"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="手机号"
            prop="driverPhone"
          >
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            label="毛重"
            prop="roughWeight"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="皮重" prop="tare">
          </el-table-column>
          <el-table-column show-overflow-tooltip label="净重" prop="suttle">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="装货日期"
            prop="loaddate"
            min-width="110"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="过重时间"
            prop="mwitemdate"
          >
          </el-table-column>
          <el-table-column label="操作" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="handleEditPoundBill(scope.row)"
              >
                修改磅单
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="color: rgb(254, 90, 36)"
                @click.stop="handleDelete(scope.row.id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
    <AddPoundBillDialog
      ref="addPoundBillDialogRef"
      v-if="addPoundVisible"
      @confirm="handlePoundConfirm"
      @cancel="handlePoundCancel"
    ></AddPoundBillDialog>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import AddPoundBillDialog from "../module/addPoundBillDialog";
export default {
  data() {
    return {
      isFleet: this.$route.query.isFleet ? this.$route.query.isFleet : false,
      conditions: {
        fleetId: "",
        page: 1,
        limit: 10,
        sendOwnerId: "",
        receiveOwnerId: "",
        transportCode: "",
        pno: "",
        dispatchFrom: "",
        loadingDate: "",
      },
      fleetOptions: [],
      showMoreConditions: false,
      checkedIds: [],
      enumStatus: {
        0: "待接单",
        1: "已拒绝",
        2: "已接单",
        10: "待审核",
        11: "未通过",
        20: "待起运",
        21: "待送达",
        22: "已完工",
      },
      ownerOptions: [],
      tableList: [],
      total: 0,
      addPoundVisible: false,
    };
  },
  components: {
    Pagination,
    AddPoundBillDialog,
  },
  created() {
    this.getOwnerOptions();
    if (this.isFleet) {
      this.getOwnFleetOptions();
    } else {
      this.getFleetOptions();
      this.getList();
    }
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    async getOwnerOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/list",
        "get",
        {}
      );
      this.ownerOptions = data.data.list;
    },
    async getFleetOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/list",
        "get",
        {}
      );
      this.fleetOptions = data.data.list;
    },
    async getOwnFleetOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/ownList",
        "get",
        {}
      );
      this.fleetOptions = data.data;
      if (this.fleetOptions.length > 0) {
        this.conditions.fleetId = this.fleetOptions[0].id;
        this.getList();
      }
    },
    handleReset(formName) {
      if (!this.isFleet) {
        this.conditions.fleetId = "";
      }
      this.$refs.tableRef.clearSelection();
      this.$refs[formName].resetFields();
      this.conditions.limit = 10;
      this.conditions.page = 1;
      this.getList();
      this.showMoreConditions = false;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    // 获取列表数据
    async getList(num) {
      typeof num === "number" && (this.conditions.page = num);
      const { data } = await this.$http(
        "api1",
        "/api/biz/PoundBill/list",
        "get",
        this.conditions
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    // 获取货场列表
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    goDetails(row) {
      this.$router.push({
        path: "/check/transportBillDetails",
        query: { id: row.id, transportBillId: row.transportBillId },
      });
    },
    handleEditPoundBill(row) {
      this.addPoundVisible = true;
      this.$nextTick(() => {
        this.$refs.addPoundBillDialogRef.isEdit = true;
        this.$refs.addPoundBillDialogRef.poundBillId = row.id;
        this.$refs.addPoundBillDialogRef.form.driverPhone = row.driverPhone;
        this.$refs.addPoundBillDialogRef.form.plate = row.plate;
        this.$refs.addPoundBillDialogRef.form.roughWeight = row.roughWeight;
        this.$refs.addPoundBillDialogRef.form.tare = row.tare;
        this.$refs.addPoundBillDialogRef.form.suttle = row.suttle;
        this.$refs.addPoundBillDialogRef.form.mwitemdate = row.mwitemdate;
        this.$refs.addPoundBillDialogRef.form.attachUrl = row.attachUrl;
      });
    },
    handlePoundConfirm() {
      this.addPoundVisible = false;
      this.getList();
    },
    handlePoundCancel() {
      this.addPoundVisible = false;
    },
    handleDelete(id) {
      this.$messageBox
        .confirm("确认删除该磅单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/PoundBill/delete",
            "post",
            {
              id: id,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getList();
          }
        });
    },
    handleCollapse() {
      this.showMoreConditions = !this.showMoreConditions;
    },
    async handleCommand(command) {
      if (this.isFleet && !this.conditions.fleetId) {
        return this.$message.error("暂无关联车队");
      }
      if (command == "a") {
        this.$download(
          "post",
          "/api/biz/PoundBill/listExport",
          this.conditions
        );
      } else if (command == "b") {
        if (!this.checkedIds || this.checkedIds.length == 0) {
          return this.$message.error("请选择要导出的数据");
        }
        this.$download("post", "/api/biz/PoundBill/listExport", {
          ids: this.checkedIds.toString(),
        });
      }
    },
    handleSelectionChange(val) {
      this.checkedIds = val.map((item) => item.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
